import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import { Header } from "./header/Header";
import { DemoCarousel } from "./carousel/Carousel";
import { OverlaySpinner } from "../common/loaders/OverlaySpinner";
import { GameWindow } from "../games/GameWindow";
import { usePlayGames } from "../../features/play_games/usePlayGames";
import { Footer } from "./footer/Footer";
import { BottomMobileMenu } from "./bottom_menu/BottomMobileMenu";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";

interface PageLayoutProps {
    children: ReactNode
    pageName?: string;
    carouselDisabled?: boolean
    footerDisabled?: boolean
    flexGrowContentDisabled?: boolean
    mobileBottomMenuDisabled?: boolean
}

export const PageLayout: FC<PageLayoutProps> = ({ children, pageName, carouselDisabled = false, footerDisabled = false, flexGrowContentDisabled = false, mobileBottomMenuDisabled = false }) => {
    const { isGameSessionLoading, gameSession, isOutcomeGameLoading } = usePlayGames()
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [scrollContainerHeight, setScrollContainerHeight] = useState(0);
    const { largeScreen } = useCommonMediaQueries()
    // const [headerHeight, setHeaderHeight] = useState(0)

    useEffect(() => {
        const siteName = process.env.REACT_APP_SITE_NAME ?? "Casino"
        document.title = pageName ? `${siteName} | ${pageName}` : siteName;

        return () => {
            document.title = siteName;
        };
    }, [pageName]);

    useEffect(() => {
            if (scrollContainerRef.current) {
                const height = scrollContainerRef.current.offsetHeight;
                setScrollContainerHeight(height);
            }
    }, []);

    return <Stack
        height={'100dvh'}
    >
        <Header
            // onHeightChange={setHeaderHeight}
        />
        <Stack
            ref={scrollContainerRef}
            flexGrow={1}
            sx={{overflowY: 'auto', scrollbarWidth: 'thin', scrollbarColor: '#15181D #15181D60'}}
        >
            <Stack position={'relative'} minHeight={scrollContainerHeight}>
                {!carouselDisabled && <DemoCarousel/>}
                { children }
                {!flexGrowContentDisabled && <Box flexGrow={1}/>}
                {!footerDisabled && <Footer scrollContainerRef={scrollContainerRef}/>}
            </Stack>
                {(isOutcomeGameLoading || isGameSessionLoading) && <OverlaySpinner/>}
                {gameSession.SessionUrl && <GameWindow
                    // topOffset={headerHeight}
                />}
        </Stack>

        {largeScreen && !mobileBottomMenuDisabled && <BottomMobileMenu/>}
    </Stack>
}