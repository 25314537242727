import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from "react";

const HomeIcon: FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 7H5" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                <path d="M19 12H9" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                <path d="M19 17H5" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
            </svg>

        </SvgIcon>
    );
}

export default HomeIcon;