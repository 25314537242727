import { FC, ReactNode } from "react";
import { Stack } from "@mui/material";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";

interface CarouselSlideProps {
    children?: ReactNode
    bgImage?: string
    backgroundPosition?: string
}
export const CarouselSlide: FC<CarouselSlideProps> = ({children, bgImage, backgroundPosition}) => {
    const {  mediumScreen } = useCommonMediaQueries()

    return <Stack
        height={mediumScreen ? '40vw' : '300px'}
        alignItems={'center'}
        sx={{
            backgroundImage: bgImage ? `url(${bgImage})` : undefined,
            backgroundSize: 'cover',
            backgroundPosition: backgroundPosition ?? 'center',
            backgroundRepeat: 'no-repeat',
            maskImage: "linear-gradient(rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%)"
        }}
    >
        <Stack
            pr={'16px'}
            width={mediumScreen ? '100%' : '1000px'}
            direction={'row'}
            height={'100%'}
        >
        {children}
        </Stack>
    </Stack>
}