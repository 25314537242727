import { FC, useEffect, useState } from "react";
import { PageLayout } from "../../components/oropuro_layout/PageLayout";
import { GameSections } from "../../components/oropuro_layout/category_cards/GameSections";
import { TopGamesSection } from "../../components/oropuro_layout/top_games_section/TopGamesSection";
import { Box } from "@mui/material";
import { SectionDivider } from "../../components/oropuro_layout/section_devider/SectionDivider";
import { ProvidersListSection } from "../../components/oropuro_layout/providers_section/ProvidersListSection";
import { FirstGroupedGames } from "../../components/oropuro_layout/games/FirstGroupedGames";
import { useLanguage } from "../../features/localisation/useLanguage";
import { reFilterGamesByType } from "../../features/play_games/playGamesSlice";
import { useAppDispatch } from "../../store";


export const HomePage: FC = () => {
    const dispatch = useAppDispatch()
    const { languagePack: { pack: { argentina: { common: lang } } } } = useLanguage()
    const [justMounted, setJustMounted] = useState<boolean>(true)

    useEffect(() => {
        if (justMounted) {
            setJustMounted(false)
                dispatch(reFilterGamesByType(null))
        }
    }, [dispatch, justMounted])

    return <PageLayout pageName={'Home'}>
        <Box px={'8px'}>
            <GameSections/>
        </Box>
        <Box padding={'24px 8px'}>
            <TopGamesSection/>
        </Box>
        <Box px={'8px'}>
            <SectionDivider>
                {lang.providers}
            </SectionDivider>
        </Box>
        <Box px={'8px'} mb={'24px'}>
            <ProvidersListSection/>
        </Box>
        <Box px={'8px'} mb={'24px'}>
            <FirstGroupedGames/>
        </Box>
    </PageLayout>
}