import React, { FC } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { CarouselSlide } from "./CarouselSlide";
import { Box, Stack } from "@mui/material";
import { CarouselSlideGradientTitle } from "./CarouselSlideGradientTitle";
import { CarouselSlideSecondaryTitle } from "./CarouselSlideSecondaryTitle";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { useLanguage } from "../../../features/localisation/useLanguage";

import LuckyCat from '../../../../assets/salta-la-banca/carousel-slides/lucky_cat.webp'
import LuckyCat2x from '../../../../assets/salta-la-banca/carousel-slides/lucky_cat@2x.webp'
import VaultBg from '../../../../assets/salta-la-banca/carousel-slides/vault_bg.webp'

import Totem from '../../../../assets/salta-la-banca/carousel-slides/totem.webp'
import Totem2x from '../../../../assets/salta-la-banca/carousel-slides/totem@2x.webp'
import GreenValleyBg from '../../../../assets/salta-la-banca/carousel-slides/greeen_valley_bg.webp'

import SoccerFieldBg from '../../../../assets/salta-la-banca/carousel-slides/soccer_field_bg.webp'
import SoccerPlayer from '../../../../assets/salta-la-banca/carousel-slides/soccer_player.webp'
import SoccerPlayer2x from '../../../../assets/salta-la-banca/carousel-slides/soccer_player@2x.webp'

import Hostes from '../../../../assets/salta-la-banca/carousel-slides/salta_hostes.webp'
import Hostes2x from '../../../../assets/salta-la-banca/carousel-slides/salta_hostes@2x.webp'
import StudioBg from '../../../../assets/salta-la-banca/carousel-slides/studio_bg.webp'

import Digitain from '../../../../assets/salta-la-banca/carousel-slides/providers_logo/digitain_logo.webp'

export const DemoCarousel: FC = () => {
    const { languagePack: {pack: {argentina: {carousel: lang}}}} = useLanguage()
    const { xxSmallScreen, smallScreen, mediumScreen } = useCommonMediaQueries()
    const logoSize = xxSmallScreen ? 0.65 : smallScreen ? 0.95 : mediumScreen ? 1.2 : 1.6

    return <Carousel
        showThumbs={false}
        autoPlay={true}
        // autoPlay={false}
        infiniteLoop={true}
        showStatus={false}
        interval={6000}>

        <CarouselSlide bgImage={GreenValleyBg}>
            <Box
                flex={2}
                sx={{
                    backgroundImage: `url(${xxSmallScreen ? Totem : Totem2x})`,
                    backgroundSize: '120%',
                    backgroundPosition: 'top center',
                    backgroundRepeat: 'no-repeat',
                }}/>
            <Stack flex={mediumScreen ? 3 : 4} justifyContent={'center'}>
                <CarouselSlideSecondaryTitle color={'#6e0779'}>
                    {lang.slide1.header.split('<br/>')[0]}
                </CarouselSlideSecondaryTitle>
                <CarouselSlideGradientTitle gradient={'linear-gradient(to top, #310938, #ffb0e9)'}>
                    {lang.slide1.header.split('<br/>')[1]}
                </CarouselSlideGradientTitle>
                <CarouselSlideSecondaryTitle color={'#6e0779'}>
                    {lang.slide1.tagline}
                </CarouselSlideSecondaryTitle>
            </Stack>
        </CarouselSlide>
        <CarouselSlide bgImage={SoccerFieldBg}>
            <Box
                flex={2}
                sx={{
                    backgroundImage: `url(${xxSmallScreen ? SoccerPlayer : SoccerPlayer2x})`,
                    backgroundSize: '100%',
                    backgroundPosition: 'top center',
                    backgroundRepeat: 'no-repeat',
                }}/>
            <Stack flex={mediumScreen ? 3 : 4} justifyContent={'center'}>
                <CarouselSlideSecondaryTitle color={'#edaaf8'}>
                    {lang.slide4.header.split('<br/>')[0]}
                </CarouselSlideSecondaryTitle>
                <CarouselSlideGradientTitle gradient={'linear-gradient(to right, #fdefff, #ffb0e9)'}>
                    {lang.slide4.header.split('<br/>')[1]}
                </CarouselSlideGradientTitle>
                <CarouselSlideSecondaryTitle color={'#edaaf8'}>
                    {lang.slide4.tagline}
                </CarouselSlideSecondaryTitle>
                <Box
                    width={`${logoSize * 116}px`}
                    height={`${logoSize * 20}px`}
                    sx={{
                        backgroundImage: `url(${Digitain})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}
                />
                {/*</Link>*/}
            </Stack>
        </CarouselSlide>
        <CarouselSlide bgImage={StudioBg}>
            <Box
                flex={2}
                sx={{
                    backgroundImage: `url(${xxSmallScreen ? Hostes : Hostes2x})`,
                    backgroundSize: '100%',
                    backgroundPosition: 'top',
                    backgroundRepeat: 'no-repeat',
                }}/>
            <Stack flex={mediumScreen ? 3 : 4} justifyContent={'center'}>
                <CarouselSlideSecondaryTitle>
                    {lang.slide3.header.split('<br/>')[0]}
                </CarouselSlideSecondaryTitle>
                <CarouselSlideGradientTitle>
                    {lang.slide3.header.split('<br/>')[1]}
                </CarouselSlideGradientTitle>
                <CarouselSlideSecondaryTitle>
                    {lang.slide3.tagline}
                </CarouselSlideSecondaryTitle>
            </Stack>
        </CarouselSlide>
        <CarouselSlide bgImage={VaultBg} backgroundPosition={'bottom'}>
            <Box
                flex={2}
                sx={{
                    backgroundImage: `url(${xxSmallScreen ? LuckyCat : LuckyCat2x})`,
                    backgroundSize: '100%',
                    backgroundPosition: 'top',
                    backgroundRepeat: 'no-repeat',
                }}/>
            <Stack flex={mediumScreen ? 3 : 4} justifyContent={'center'}>
                <CarouselSlideSecondaryTitle>
                    {lang.slide2.header.split('<br/>')[0]}
                </CarouselSlideSecondaryTitle>
                <CarouselSlideGradientTitle>
                    {lang.slide2.header.split('<br/>')[1]}
                </CarouselSlideGradientTitle>
                <CarouselSlideSecondaryTitle>
                    {lang.slide2.tagline}
                </CarouselSlideSecondaryTitle>
            </Stack>
        </CarouselSlide>
    </Carousel>
};