import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { alpha } from "@mui/material";
import {theme} from "../../../themes/salta_la_banca/salta_la_banca_theme";

interface HeaderMenuItemProps {
    to: string;
    text: string;
    isActive: boolean;
}

const HeaderMenuItem: FC<HeaderMenuItemProps> = ({ to, text, isActive }) => {
    return (
        <Link to={to}>
            <Typography
                textTransform={'uppercase'}
                component={'li'}
                px={'20px'}
                py={'25px'}
                sx={{
                    listStyle: 'none',
                    margin: 0,
                    color: isActive ? theme.palette.primary.main : alpha(theme.palette.text.primary, 0.6),
                    '&:hover': {
                        color: theme.palette.text.primary
                    }
                }}
            >
                {text}
            </Typography>
        </Link>
    );
};

export default HeaderMenuItem;
