import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from "react";

const TransactionIcon: FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.4986 10.5248C0.670939 10.5248 0 9.85384 0 9.02616C0 8.49062 0.280958 8.02066 0.703534 7.75566L4.73121 3.72799C5.31646 3.14275 6.26531 3.14275 6.85056 3.72799C7.4358 4.31324 7.4358 5.26209 6.85056 5.84734L5.17028 7.5276L16.4847 7.5276C17.3123 7.5276 17.9833 8.19856 17.9833 9.02616C17.9833 9.85384 17.3123 10.5248 16.4847 10.5248L1.57271 10.5248C1.55903 10.5251 1.54536 10.5251 1.53169 10.5248H1.4986Z" fill="currentColor"/>
                <path d="M22.5003 13.4767C23.328 13.4767 23.9989 14.1477 23.9989 14.9753C23.9989 15.5109 23.7179 15.9808 23.2953 16.2459L19.2677 20.2736C18.6825 20.8588 17.7335 20.8588 17.1483 20.2736C16.563 19.6884 16.563 18.7395 17.1483 18.1543L18.8286 16.4739L7.51423 16.4739C6.68656 16.4739 6.01562 15.803 6.01562 14.9753C6.01562 14.1477 6.68656 13.4767 7.51423 13.4767L22.4261 13.4767C22.4398 13.4765 22.4535 13.4765 22.4672 13.4767H22.5003Z" fill="currentColor"/>
            </svg>
        </SvgIcon>
    );
}

export default TransactionIcon;