export const chunkArray = <T>(array: T[], chunkSize: number): T[][] => {
    return Array.from({ length: Math.ceil(array.length / chunkSize) }, (_, index) => array.slice(index * chunkSize, index * chunkSize + chunkSize));
}


// Email validation function
export const validateEmail = (email: string, emailIsRequiredMess: string, notValidEmail: string) => {
    if (!email) {
        return `${emailIsRequiredMess}`;
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
        return `${notValidEmail}`;
    }
    return ''; // Empty string means validation passed
};


export const convertCentsToCurrencyString = (userBalance: number): string => {
    let result: string;

    const b = userBalance / 100

    if (b.toString().includes('.')) {
        const parts = b.toString().split('.')
        if (parts[1].length < 2) {
            parts[1] = parts[1] + '0'
            if (parts[1].length < 2) {
                parts[1] = parts[1] + '0'
            }
        }
        result = parts.join('.')
    } else {
        result = b.toString() + '.00'
    }

    return result
}

export const convertStringCurrencyToCents = (currency: string): number => {

    if (currency.replace(',', '.').includes('.')) {
        const parts = currency.replace(',', '.').split('.')
        parts[parts.length - 1] = parts[parts.length - 1].slice(0, 2).padEnd(2, '0')
        return parseInt(parts.join(''))
    } else {
        return parseInt(currency + '00')
    }
}

export const getNumberParam = (paramValue: string|null): number|null => {
    if (paramValue && !isNaN(Number(paramValue))) {
        return Number(paramValue)
    } else {
        return null
    }
}

/**
 * Converts Unix timestamp to a compact formatted local date and time string.
 * @param unixTime - Unix timestamp (in seconds).
 * @returns Formatted local date and time string in the format "DD.MM.YYYY HH:mm:ss".
 */
export const convertUnixToCompactLocaleDate = (unixTime: number): string => {
    // Create a new Date object using the provided Unix timestamp (converted to milliseconds).
    const date = new Date(unixTime * 1000);

    // Options for formatting the date.
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };

    // Get the formatted date and time string using the specified options.
    const formattedDate = date.toLocaleString(undefined, options);

    // Extract day, month, year, and time components from the formatted string.
    const day = formattedDate.slice(0, 2);
    const month = formattedDate.slice(3, 5);
    const year = formattedDate.slice(6, 10);
    const time = formattedDate.slice(12);

    // Assemble the compact date and time string in the desired format.
    return `${day}.${month}.${year} ${time}`;
}

export interface SeparatedDateTime {
    date: string
    time: string
}

export const separateDateTime = (dateTimeString: string): SeparatedDateTime => {
    const parts = dateTimeString.split(' ')
    parts[1] = parts[1].split(':').slice(0, 2).join(':')
    return {
        date: parts[0],
        time: parts[1]
    }
}
export const normalizePath = (path: string) => path.replace(/\/+$/, '');


export const getProductStage = (): 'production' | 'staging' | 'local' => {
    const domain = window.location.hostname;

    if (domain === 'localhost') {
        const envDomain = process.env.REACT_APP_CURRENT_DOMAIN;

        if (envDomain === 'saltalabanca.net') {
            return 'production';
        }

        if (envDomain === 'staging.saltalabanca.net') {
            return 'staging';
        }

        return 'local';
    }

    if (domain === 'saltalabanca.net') {
        return 'production';
    }

    if (domain === 'staging.saltalabanca.net') {
        return 'staging';
    }

    return 'local';
};

export const getDeviceType = (): 'mobile' | 'desktop' => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (/mobile|android|iphone|ipad|tablet/i.test(userAgent)) {
        return 'mobile';
    }

    return 'desktop';
};