import { FC } from "react";
import { Box } from "@mui/material";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import logo from '../../../../assets/salta-la-banca/Logo.webp'
import logo2x from '../../../../assets/salta-la-banca/Logo@2x.webp'

export const Logo: FC = () => {
    const {xxSmallScreen} = useCommonMediaQueries()

    return <Box
        width={xxSmallScreen ? '120px' : '196px'}
        height={xxSmallScreen ? '25px' : '40px'}
        sx={{
            backgroundImage: `url(${xxSmallScreen ? logo : logo2x})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'contain',
        }}
    />
}