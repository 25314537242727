import React from 'react';
import ReactDOM from 'react-dom/client';
import './app/themes/salta_la_banca/salta_la_banca_theme.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './app/store'
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ApiErrorDialog } from "./app/components/common/ApiErrorDialog/ApiErrorDialog";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import {theme} from "./app/themes/salta_la_banca/salta_la_banca_theme";


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);



root.render(
    <BrowserRouter>
        <Provider store={store}>
            <SnackbarProvider maxSnack={5}>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                        <App/>

                    <ApiErrorDialog/>
                </ThemeProvider>
            </SnackbarProvider>
        </Provider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
