import { FC } from "react";
import { Link } from "react-router-dom";
import { alpha, Stack, SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { normalizePath } from "../../../features/common_funcs";
import Typography from "@mui/material/Typography";

interface DashboardMenuItemProps {
    linkUrl?: string
    children: string
    IconComponent: OverridableComponent<SvgIconTypeMap<{}, "svg">>
    onClick?: () => void
    compact?: boolean
}

export const DashboardMenuItem: FC<DashboardMenuItemProps> = (props) => {
    return props.linkUrl ? <Link to={props.linkUrl}><DashboardMenuItemBlock {...props}/></Link> :
        <DashboardMenuItemBlock {...props}/>
}


const DashboardMenuItemBlock: FC<DashboardMenuItemProps> = ({ children, IconComponent, onClick = () => {}, linkUrl, compact = false }) => {
    const currentPath = normalizePath(window.location.pathname)
    const isActive = linkUrl && currentPath === normalizePath(linkUrl)
    const color = isActive ? 'primary.main' : alpha('#fff', 0.6)
    const backgroundColor = '#15181D'

    return <Stack
        alignItems={'center'}
        onClick={onClick}
        borderRadius={'10px'}
        gap={compact ? '10px' : '16px'}
        px={compact ? '10px' : '16px'}
        py={compact ? '8px' : '12px'}
        direction={'row'}
        sx={{
            cursor: 'pointer',
            backgroundColor: isActive ? backgroundColor : 'transparent',
            '&:hover': {
                backgroundColor: backgroundColor,
            },
        }}>
        <IconComponent
            sx={{
                color,
                transition: 'color 0.3s',
                width: compact ? '24px' : '32px',
                height: compact ? '24px' : '32px'
            }}
        />
        <Typography sx={{userSelect: 'none'}} color={color} fontSize={compact ? '16px' : '22px'}>
            {children}
        </Typography>
    </Stack>
}