import { FC } from "react";
import { Box, Chip, Paper } from "@mui/material";
import { GameHistoryModels } from "../../features/gameHistory/GameHistoryModels";
import Typography from "@mui/material/Typography";
import { convertCentsToCurrencyString, separateDateTime, SeparatedDateTime } from "../../features/common_funcs";
import { CurrencyRender } from "../common/CurrencyRender";

interface GamesHistoryItemCardProps {
    gameHistoryItem: GameHistoryModels
    currency?: string | null
}

export const GamesHistoryItemCard: FC<GamesHistoryItemCardProps> = ({ gameHistoryItem, currency }) => {
    const separatedDateTime: SeparatedDateTime = gameHistoryItem.time ? separateDateTime(gameHistoryItem.time) : {
        date: '--.--.----',
        time: '--:--'
    }

    const currencyRender = currency ? <CurrencyRender currency={currency}/> : <></>

    return <Paper sx={{
        borderRadius: '11px'
    }}>
        <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={'6px'}
            padding={'10px'}
        >
            <Typography fontWeight={700} fontSize={'14px'} textTransform={'uppercase'}>
                {gameHistoryItem.game_name}
            </Typography>

            <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-start'}>
                <Box display={'flex'} color={'rgba(255,255,255, 0.5)'} alignItems={'flex-end'} columnGap={'6px'}>
                    <Typography fontSize={'12px'} mb={'-2px'} fontWeight={700}>
                        {separatedDateTime.time}
                    </Typography>
                    <Typography fontSize={'8px'} fontWeight={300}>
                        {separatedDateTime.date}
                    </Typography>
                </Box>

                <Chip label={gameHistoryItem.provider_name}/>
            </Box>

            <Box display={'flex'}>
                <Box minWidth={'130px'} color={'#d90700'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'14px'} fontWeight={700} textTransform={'uppercase'} mb={'-2px'}>
                        Bet: {gameHistoryItem.withdraw_sum ? convertCentsToCurrencyString(gameHistoryItem.withdraw_sum) : '—'}
                    </Typography>
                    {Boolean(gameHistoryItem.withdraw_sum) && currencyRender}
                </Box>

                <Box minWidth={'130px'} color={'#4EB100'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'14px'} fontWeight={700} textTransform={'uppercase'} mb={'-2px'}>
                        Win: {gameHistoryItem.deposit_sum ? convertCentsToCurrencyString(gameHistoryItem.deposit_sum) : '—'}
                    </Typography>
                    {Boolean(gameHistoryItem.deposit_sum) && currencyRender}
                </Box>
            </Box>
        </Box>
    </Paper>
}

