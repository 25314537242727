import { LangPack } from "./langModel";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { esLang } from "./packs/esLang";

interface LanguageState {
    languagePack: LangPack
}

const initialState: LanguageState = {
    languagePack: esLang
}

export const LanguageSlice = createSlice({
    initialState,
    name: 'language',
    reducers: {
        changeLang: (state, action: PayloadAction<LangPack>) => {
            state.languagePack = action.payload
        }
    }
})

export default LanguageSlice.reducer

export const { changeLang } = LanguageSlice.actions