import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";

export const MediaqueryIndicator: FC = () => {
    const { xxSmallScreen, smallScreen, mediumScreen, largeScreen, xLargeScreen } = useCommonMediaQueries()
    const localTesting = process.env.REACT_APP_ENVIRONMENT === 'live'

    return localTesting ? <Stack padding={'2px'} borderRadius={'4px'} minWidth={'30px'} alignItems={'center'} position={'fixed'} top={0} left={0} sx={{
        backgroundColor: xxSmallScreen ? '#e8052f' : smallScreen ? '#fd6900' : mediumScreen ? '#ffc400' : largeScreen ?
            '#96da21' : xLargeScreen ? '#00d9ff' : '#3a55de',
        opacity: 0.8,
        '&:hover': {
            opacity: 1,
        },
    }}>
        <Typography fontSize={'10px'} lineHeight={1}>
            {xxSmallScreen ? 'XS' : smallScreen ? 'SM' : mediumScreen ? 'MD' : largeScreen ? 'LG' : xLargeScreen ? 'XL' : 'XXL'}
        </Typography>
        <Typography fontSize={'8px'} lineHeight={1}>
            {xxSmallScreen ? '450' : smallScreen ? '767' : mediumScreen ? '960' : largeScreen ? '1200' : xLargeScreen ? '1536' : '1536+'}
        </Typography>
    </Stack> : <></>
}