import React, { FC } from "react";
import { TopGameCard } from "./TopGameCard";
import { TopGameHolder } from "./models/models";
import { Stack } from "@mui/material";

interface TopGamesListDesktopProps {
    gamesList: TopGameHolder[]
    onGameClick?: (game: TopGameHolder) => () => void
}

export const TopGamesListDesktop: FC<TopGamesListDesktopProps> = ({ gamesList, onGameClick = () => () => {} }) => {
    return (
        <Stack gap={'0.8vw'}>
            <Stack direction={'row'}
                   sx={{
                       overflowX: 'auto',
                       '&::-webkit-scrollbar': { display: 'none' },
                       '-ms-overflow-style': 'none',  /* IE and Edge */
                       'scrollbar-width': 'none'  /* Firefox */
                   }}
                   gap={'0.8vw'}>
                {gamesList.length > 0 && gamesList.slice(0, Math.ceil(gamesList.length / 2)).map((game, index) => (
                    <TopGameCard game={game} key={`row-1-${index}-${game.gameID}`} onClick={onGameClick(game)}/>
                ))}
            </Stack>
            <Stack direction={'row'}
                   sx={{
                       overflowX: 'auto',
                       '&::-webkit-scrollbar': { display: 'none' },
                       '-ms-overflow-style': 'none',  /* IE and Edge */
                       'scrollbar-width': 'none'  /* Firefox */
                   }}
                   gap={'0.8vw'}>
                {gamesList.length > 0 && gamesList.slice(Math.ceil(gamesList.length / 2), gamesList.length).map((game, index) => (
                    <TopGameCard game={game} key={`row-2-${index}-${game.gameID}`} onClick={onGameClick(game)}/>
                ))}
            </Stack>
        </Stack>
    )
}