import React, { FC, useState } from "react";
import { Box, Stack } from "@mui/material";
import { DashboardMenuItem } from "./DashboardMenuItem";
import UserInfoIcon from "../svg/UserInfoIcon";
import TransactionIcon from "../svg/TransactionIcon";
import GameHistoryIcon from "../svg/GameHistoryIcon";
import LogoutIcon from "../svg/LogoutIcon";
import { LogOut } from "../../../pages/DashboardV2/LogOut";
import { useLanguage } from "../../../features/localisation/useLanguage";
import { useUser } from "../../../features/user/useUser";
// import PaymentAccountIcon from "../svg/PaymentAccountIcon";

interface DashboardMenuProps {
    compact?: boolean
}

export const DashboardMenu: FC<DashboardMenuProps> = ({ compact = false }) => {
    const { languagePack: { pack: { argentina: { dashboard: { sideMenu: lang } } } } } = useLanguage()
    const [ showLogout, setShowLogout ] = useState<boolean>(false)
    const {user, token} = useUser()

    return <>
        <Box>
            {user && token && <Stack padding={'8px'} gap={'8px'}>
                    <DashboardMenuItem IconComponent={UserInfoIcon} linkUrl={'/dashboard/user_info'}
                                       compact={compact}>{lang.userInfo}</DashboardMenuItem>
                    <DashboardMenuItem IconComponent={TransactionIcon}
                                       linkUrl={'/dashboard/transactions'}
                                       compact={compact}>{lang.transactions}</DashboardMenuItem>
                    <DashboardMenuItem IconComponent={GameHistoryIcon} linkUrl={'/dashboard/game_history'}
                                       compact={compact}>{lang.gamesHistory}</DashboardMenuItem>
                    {/*<DashboardMenuItem IconComponent={PaymentAccountIcon} linkUrl={'/dashboard/payment_account'} compact={compact}>Payment*/}
                    {/*    accounts</DashboardMenuItem>*/}
                <DashboardMenuItem IconComponent={LogoutIcon}
                                   onClick={() => setShowLogout(true)} compact={compact}>{lang.logoutBTN}</DashboardMenuItem>
            </Stack>}
        </Box>
        {showLogout && <LogOut close={() => {
            setShowLogout(false)
        }}/>}
    </>
}