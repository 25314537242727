import { FC, RefObject } from "react";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { MobileFooter } from "./MobileFooter";
import { DesktopFooter } from "./DesktopFooter";

export interface FooterProps {
    scrollContainerRef: RefObject<HTMLDivElement>;
}

export const Footer: FC<FooterProps> = (props) => {
    const { smallScreen } = useCommonMediaQueries()

    return smallScreen ? <MobileFooter {...props}/> : <DesktopFooter {...props}/>
}