import { FC } from "react";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

interface UserTitleProps {
    username: string
}

export const UserTitle: FC<UserTitleProps> = ({username}) => {
    return <Stack
        direction={'row'}
        borderRadius={'10px'}
        px={'10px'}
        py={'2px'}
        sx={{
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: 'grey.600',
        }}
        gap={'5px'}
        alignItems={'center'}
        // p={'5px'}
    >
        <Typography textTransform={'uppercase'} fontWeight={600} fontSize={'14px'}>
            {username}
        </Typography>
        <AccountCircleIcon color={'primary'}/>
    </Stack>
}