import { SectionDivider } from "../section_devider/SectionDivider";
import { usePlayGames } from "../../../features/play_games/usePlayGames";
import { Fragment } from "react";
import { ShortGamesList } from "./ShortGamesList";
import { useUser } from "../../../features/user/useUser";
import { useSnackbar, VariantType } from "notistack";
import { GameObj } from "../../../features/play_games/playGamesModels";
import { useLanguage } from "../../../features/localisation/useLanguage";

export const FirstGroupedGames = () => {
    const { languagePack: { pack: { argentina: { common: lang } } } } = useLanguage()
    const { groupedGames } = usePlayGames()
    const { token } = useUser();
    const { isGameSessionLoading, startGameSession } = usePlayGames();
    const { enqueueSnackbar } = useSnackbar();

    const sendSnackBar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, { variant });
    };

    const clickHandler = () => {
        return token ? (game: GameObj) => {
            if (!isGameSessionLoading) {
                startGameSession(game.internal_id)
            }
        } : () => {
            console.log('Login required')
            sendSnackBar('warning', 'Login required')
        }
    }

    return <>
        {groupedGames !== null && groupedGames.slice(0, 5).map(group => <Fragment key={group.provider.provider_name}>
            <SectionDivider link={`/games?providerName=${group.provider.provider_name}`} linkName={lang.seeAllBTN}>
                {group.provider.title}
            </SectionDivider>
            <ShortGamesList games={group.games.slice(0, 9)} onGameClick={clickHandler()}/>
        </Fragment>)}
    </>;
};