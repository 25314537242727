import { LangPack } from "../langModel";
import { Lang } from "../useLanguageInterface";

export const esLang: LangPack = {
    lang: Lang.ES,
    langName: "Spanish",
    langShort: "es",
    pack: {
        common: {
            noBTN: "No",
            yesBTN: "Sí",
            errorCodeTitle: "Código de error",
            pageSizeSwitcherLabel: "Filas por página",
            ageConfirmationMessage: "¿Confirma que tiene 18 años?",
            iConfirmBTN: "Confirmo",
        },
        dashboardMenu: {
            logOutConfirmationMessage: "¿Estás seguro de que quieres cerrar sesión?",
            logOut: "Cerrar sesión",
            depositMoney: "Depositar dinero",
            gamesHistory: "Historial de juegos",
            transactions: "Transacciones",
            paymentAccounts: "Cuentas de pago",
            userInfo: "Información de usuario",
            withDrawMoney: "Retirar dinero"
        },
        mobileMenu: {
            profile: 'Perfil',
            topUpBalance: 'Recargar saldo',
            logout: 'Cerrar sesión'
        },
        dashboard: {
            title: 'Tablero',
            youMustBeLoggedMessage: '¡Debes iniciar sesión para ver esta sección!'
        },
        internalErrorMessages: {
            networkError: "Se produjo un error de red al ejecutar la solicitud.",
            error502: "Se produjo un error 502 del servidor al ejecutar la solicitud."
        },
        errorMessages: {
            2: "Solicitud vacía",
            3: "Correo electrónico ya existente",
            4: "Número de teléfono ya existente",
            5: "Usuario ya ha iniciado sesión",
            6: "Contraseña incorrecta",
            7: "Resultado de juegos vacío",
            8: "ID de resultado no coincide con la ID de la solicitud",
            9: "Error en la solicitud de resultado",
            10: "ID de sesión no presente en la respuesta",
            11: "Usuario ya existente",
            12: "Tu token no es válido. Inicia sesión de nuevo.",
            13: "No tienes suficientes derechos para realizar esta acción",
            14: "Falta la ID de usuario para esta acción",
            15: "Falta la cantidad de dinero",
            16: "La acción no existe",
            17: "Falta el token",
            18: "Dinero insuficiente",
            19: "Debes ser el padre de este usuario para realizar esta acción",
            20: "El usuario no existe",
            21: "Tipo de valor incorrecto para este campo",
            22: "Falta la contraseña",
            23: "Falta el nombre de usuario",
            24: "Falta la ID de juego",
            25: "Falta la nueva contraseña",
            26: "No se pudo configurar el token",
            27: "No se pudo eliminar el token",
            28: "Falta la ID de usuario",
            29: "Formato de fecha y hora incorrecto",
            30: "No se pudo actualizar la prohibición del usuario",
            31: "Este usuario debe estar en tu árbol para realizar esta acción",
            32: "Este usuario está prohibido y no puede realizar ninguna acción",
            33: "Falta la moneda",
            34: "Falta el valor máximo",
            35: "El tipo de límite debe ser único, diario, semanal o mensual",
            36: "Falta el tipo de límite",
            37: "El proveedor no existe",
            38: "Falta la cantidad de condición de nivel",
            39: "Falta el tipo de condición de nivel",
            40: "Falta el título de nivel",
            41: "El cashback para este nivel ya existe",
            42: "No existe cashback para este nivel",
            43: "El bonus para este código promocional ya existe",
            44: "El bonus no existe",
            45: "Falta el código promocional",
            46: "Falta la hora de inicio",
            47: "Falta la cantidad de condición de depósito",
            48: "Falta la apuesta",
            49: "Falta la validez en días",
            50: "Falta el ID",
            51: "Falta el tipo de cashback, debe ser 'apuesta' o 'depósito'",
            52: "Falta la tasa de cashback",
            53: "Esta moneda no está disponible",
            54: "Falta el parámetro de prohibición - true o false",
            55: "El grupo bancario para esta moneda no es válido",
            56: "Falta el nombre del método de pago",
            57: "Faltan parámetros obligatorios para este método de pago",
            58: "Este método de pago no existe",
            59: "Este método de pago no admite la moneda de este usuario",
            60: "Este usuario no tiene una cuenta existente para este sistema de pago",
            61: "El padre de este usuario no tiene una cuenta existente para este sistema de pago",
            62: "Falta el ID de transferencia",
            63: "No existe una transferencia con esta ID",
            64: "Esta transferencia es solicitada por un padre diferente",
            65: "Todavía tienes un bonus que no ha caducado"
        },
        loginForm: {
            usernameFieldLabel: "Apodo",
            passwordFieldLabel: "Contraseña",
            loginBTN: "Acceder"
        },
        userInfoForm: {
            emailIsRequiredMess: 'Correo electrónico obligatorio',
            notValidEmail: 'Ingresa una dirección de correo electrónico válida',
            emailFieldLabel: "Correo electrónico",
            phoneFieldLabel: "Teléfono",
            firstnameFieldLabel: "Nombre",
            lastnameFieldLabel: "Apellido",
            saveInfoBTN: "Guardar información",
            currentPassFieldLabel: "Contraseña actual",
            newPassFieldLabel: "Nueva contraseña",
            retypePassFieldLabel: "Vuelve a escribir la nueva contraseña",
            updatePassBTN: "Actualizar contraseña",
            emptyFieldWarningMessage: "Este campo no debe estar vacío",
            passNotMatchWarningMessage: "La nueva contraseña y la contraseña de confirmación no coinciden",
            usernameLabel: "Apodo"
        },
        transactionsTable: {
            amountTableHeader: "Cantidad",
            methodTableHeader: "Método",
            statusTableHeader: "Estado",
            depositWithdrawTableHeader: "Depósito/Retiro",
            dateTimeTableHeader: "Fecha/Hora"
        },
        gamesHistory: {
            providerTableHeader: 'Proveedor',
            gameTableHeader: 'Juego',
            betAmountTableHeader: 'Cantidad apostada',
            winAmountTableHeader: 'Cantidad ganada',
            dateTimeTableHeader: 'Fecha/Hora'
        },
        paymentSystems: {
            noAvailablePaymentMethodsMessage: "No hay más métodos de pago disponibles",
            paymentMethodsDropdownLabel: "Métodos de pago",
            addBTN: "Agregar",
            yourPaymentAccountsTitle: "Tus cuentas de pago",
            addPaymentMethodBTN: "Agregar método de pago"
        },
        moneyInput: {
            maxAmountMessage: "Valor máximo",
            exceedAmountError: "Se ha excedido el valor máximo.",
            moreThanOneDotError: "Ingresaste un punto extra.",
            moreThanTwoDecimals: "Ingresaste más de dos decimales."
        },
        withdrawMoney: {
            requestPayoutLabel: "Retirar de usuario",
            moneySuccessfulRequestedMessage: "Has solicitado %money% para el pago.",
            requestBtn: "Solicitar dinero"
        },
        argentina: {
            common: {
                linkToAllTitle: "Todos",
                topGames: 'Juegos principales',
                providers: 'Proveedores',
                seeAllBTN: 'Ver todos',
                returnBackBTN: 'Volver'
            },
            dashboard: {
                sideMenu: {
                    userInfo: 'Información del usuario',
                    transactions: 'Transacciones',
                    gamesHistory: 'Historial de juegos',
                    logoutBTN: 'Cerrar sesión'
                },
                userInfo: {
                    userInfoSection: 'Información del usuario',
                    changePasswordSection: 'Cambiar contraseña'
                }
            },
            carousel: {
                slide1: {
                    header: 'Domina el Totem<br/> y Alcanza la Victoria',
                    tagline: '¡Tira y Gana con Galaxsys!'
                },
                slide2: {
                    header: 'Descubre la Emoción<br/>solo en Salta la Banca',
                    tagline: '¡Únete al juego hoy mismo!'
                },
                slide3: {
                    header: 'Imagina Más, Gana Más <br/>en Imagine Live',
                    tagline: 'Las apuestas son reales'
                },
                slide4: {
                    header: "¡Siente la emoción del juego!<br/>¡Vive el momento de la victoria!",
                    tagline: "¡Únete ahora!"
                },
                slide5: {
                    header: "Ezugi está llegando",
                    tagline: "¡Al final de esta semana!"
                }
            },
            menuLinkTitles: {
                home: "Inicio",
                casino: "Casino",
                liveCasino: "Casino en vivo",
                virtualGames: "Juegos virtuales"
            },
            sideBarSections: {
                games: "Juegos",
                topCrashGames: "Juegos de crésis",
                other: "Otro",
                sportsRules: "Reglas deportivas"
            },
            headerMenu: {
                home: 'Inicio',
                casino: 'Casino',
                slots: 'Tragamonedas',
                shooters: 'Shooter',
                tableGames: 'Juegos de mesa',
                liveCasino: 'Casino en vivo',
                crash: 'Crash',
                sport: 'Deportivo',
                menu: 'Menú'
            },
            categoriesDescription: {
                casino: '¡Haga apuestas deportivas con nosotros!',
                slots: '¡Gire y gane grandes jackpots!',
                shooters: '¡Apunte, dispare y anote!',
                tableGames: '¡Juegos clásicos, diversión sin fin!',
                liveCasino: '¡Juegos con crupieres en vivo!',
                crash: '¡Apueste, observe y cobre rápido!',
                sport: '¡Juega cientos de juegos!'
            },
            footer: {
                backToTopBTN: 'Volver arriba',
                copyright: 'Todos los derechos reservados © Salta la Banca 2024<br/>18+ apueste con responsabilidad'
            }
        }
    }
}
