import React, { FC, ReactNode } from "react";
import Typography from "@mui/material/Typography";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";

interface CarouselSlideSecondaryTitleProps {
    children?: ReactNode
    color?: string
}

export const CarouselSlideSecondaryTitle: FC<CarouselSlideSecondaryTitleProps> = ({children, color}) => {
    const { smallScreen, portraitMode } = useCommonMediaQueries()

    return <Typography
        sx={{userSelect: 'none'}}
        fontWeight={500}
        textTransform={'uppercase'}
        lineHeight={1}
        color={color ?? 'white'}
        fontSize={smallScreen || portraitMode ? '3vw' : '3.5vh'}
        textAlign={'left'}
    >
        {children}
    </Typography>
}