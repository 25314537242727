import { useMediaQuery } from "@mui/material";
/*
Global version 1.5
* */

export const useCommonMediaQueries = () => {
    const portraitMode = useMediaQuery('(orientation: portrait)');
    const xxSmallScreen = useMediaQuery('(max-width: 450px)');
    const smallScreen = useMediaQuery('(max-width: 767px)');
    const mediumScreen = useMediaQuery('(max-width: 960px)');
    const largeScreen = useMediaQuery('(max-width: 1200px)');
    const xLargeScreen = useMediaQuery('(max-width: 1536px)');

    const mediumScreenMin = useMediaQuery('(min-width: 768px)');

    return {portraitMode, xxSmallScreen, smallScreen, mediumScreen, largeScreen, xLargeScreen, mediumScreenMin}
}