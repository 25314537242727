import { FC, useEffect, useState } from "react";
import { PageLayout } from "../../components/oropuro_layout/PageLayout";
import { useLanguage } from "../../features/localisation/useLanguage";
import { CircularProgress } from "@mui/material";
import { usePlayGames } from "../../features/play_games/usePlayGames";
import Stack from "@mui/material/Stack";

export const SportPage: FC = () => {
    const { languagePack: {pack: {argentina: {headerMenu: menuLang}}}} = useLanguage()
    const { openSportsBook, isSportsBookLoading, sportUrl } = usePlayGames()
    const [justMounted, setJustMounted] = useState(true)
    
    useEffect(() => {
        if (justMounted) {
            setJustMounted(false)
            openSportsBook()
        }
    }, [justMounted, openSportsBook])

    return <PageLayout pageName={menuLang.sport} carouselDisabled flexGrowContentDisabled footerDisabled mobileBottomMenuDisabled>
        <Stack height={'100%'} width={'100%'} flexGrow={1}>
            {isSportsBookLoading ? <Stack alignItems={'center'} justifyContent={'center'} height={'100%'} width={'100%'} flexGrow={1}>
                <CircularProgress/>
            </Stack> : sportUrl !== null ? <iframe title={'sport'} style={{flexGrow: 1, border: 'none'}} src={sportUrl}/> : <></>}
        </Stack>
    </PageLayout>
}