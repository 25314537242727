import { FC } from "react";
import { Box, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import { MoneyTransaction } from "../../../features/transactions/transactionModel";
import { convertCentsToCurrencyString, separateDateTime, SeparatedDateTime } from "../../../features/common_funcs";
import { CurrencyRender } from "../../common/CurrencyRender";
import { getColorForTransactionApproveStatus } from "../../transaction/transaction_funcs";

interface TransactionItemProps {
    transactionItem: MoneyTransaction
    currency?: string | null
}

export const TransactionItem: FC<TransactionItemProps> = ({ transactionItem, currency }) => {
    const separatedDateTime: SeparatedDateTime = transactionItem.respond_time ? separateDateTime(transactionItem.respond_time) : transactionItem.request_time ? separateDateTime(transactionItem.request_time) : {
        date: '--.--.----',
        time: '--:--'
    }

    const currencyRender = currency ? <CurrencyRender currency={currency}/> : <></>

    return <Paper sx={{
        borderRadius: '11px'
    }}>
        <Box
            display={'flex'}
            flexDirection={'column'}
            rowGap={'6px'}
            padding={'10px'}
            borderRadius={'11px'}
        >
            <Typography fontWeight={700} fontSize={'14px'} textTransform={'uppercase'}
                        color={getColorForTransactionApproveStatus(transactionItem.transfer_status)}>
                {transactionItem.transfer_status}
            </Typography>

            <Box display={'flex'} alignItems={'flex-start'}>
                <Box display={'flex'} color={'rgba(255,255,255, 0.5)'} alignItems={'flex-end'} columnGap={'6px'}>
                    <Typography fontSize={'12px'} mb={'-2px'} fontWeight={700}>
                        {separatedDateTime.time}
                    </Typography>
                    <Typography fontSize={'10px'} fontWeight={300}>
                        {separatedDateTime.date}
                    </Typography>
                </Box>
            </Box>

            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Box minWidth={'100px'} display={'flex'} alignItems={'center'}>
                    <Typography fontSize={'18px'} fontWeight={700} textTransform={'uppercase'} mb={'-2px'}>
                        {convertCentsToCurrencyString(transactionItem.transfer_amount)}
                    </Typography>
                    {currencyRender}
                </Box>

                <Typography fontSize={'12px'} fontWeight={300} textTransform={'uppercase'}
                            color={'rgba(255,255,255, 0.5)'}>
                    {transactionItem.transfer_type}
                </Typography>
            </Box>
        </Box>
    </Paper>
}
