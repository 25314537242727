import { createTheme } from "@mui/material/styles";
import { alpha, getContrastRatio } from "@mui/material";

declare module '@mui/material/styles' {
    interface Palette {
        surface: Palette['primary'];
    }

    interface PaletteOptions {
        surface?: PaletteOptions['primary'];
    }
}

export const theme = createTheme({
    typography: {
        fontFamily: 'Jost, sans-serif'
    },
    palette: {
        background: {
            default: '#15120c',
            paper: '#2a1d27',
        },
        mode: 'dark',
        primary: {
            light: '#F7E8D3',
            main: '#d34ecc',
            dark: '#b431ad',
            contrastText: '#18171F'
        },
        surface: {
            main: '#2a1d27',
            light: alpha('#2a1d27', 0.5),
            dark: alpha('#2a1d27', 0.9),
            contrastText: getContrastRatio('#fff', '#22242A') > 4.5 ? '#111' : '#fff',
        }
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: '12px',
                    border: `1px solid ${alpha('#fff', 0.07)}`,
                    backgroundImage: 'none'
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '12px',
                    borderWidth: '2px',
                    '&:hover': {
                        borderWidth: '2px',
                    }
                },
                outlined: ({ theme }) => theme.unstable_sx({
                    color: 'white',
                    borderColor: theme.palette.primary.main,
                    '&:hover': {
                        borderColor: theme.palette.primary.dark,
                    },
                })
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: ({ theme }) => theme.unstable_sx({
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '12px',
                        '&:hover fieldset': {
                            borderColor: theme.palette.primary.dark,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: theme.palette.primary.main,
                        },
                    },
                })
            },
        },
    },
});