import { FC } from "react";
import { Box } from "@mui/material";

interface FlagProps {
    imageUrl: string
}

export const Flag: FC<FlagProps> = ({imageUrl}) => {
    return <Box
        width={'45px'}
        height={'25px'}
        sx={{
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
        }}
    />
}