import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { Box, Stack } from "@mui/material";
import { DashboardMenu } from "../dashboard_menu/DashboardMenu";
import { Footer } from "../footer/Footer";
import { Header } from "../header/Header";
import { BottomMobileMenu } from "../bottom_menu/BottomMobileMenu";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { SectionDivider } from "../section_devider/SectionDivider";
import { DashboardMobileTitle } from "../dashboard_mobile_title/DashboardMobileTitle";
import { useUser } from "../../../features/user/useUser";
import { LoginWindow } from "../login/LoginWindow";

interface DashboardPageLayoutProps {
    children?: ReactNode
    pageName?: string;
    disableForcedMobileLogin?: boolean
}

export const DashboardPageLayout: FC<DashboardPageLayoutProps> = ({ children, pageName, disableForcedMobileLogin = false }) => {
    const { smallScreen, mediumScreenMin, largeScreen } = useCommonMediaQueries()
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [scrollContainerHeight, setScrollContainerHeight] = useState(0);

    const scrollContainerDesktopRef = useRef<HTMLDivElement>(null);
    const [scrollContainerDesktopHeight, setScrollContainerDesktopHeight] = useState(0);

    const { user, token } = useUser()

    useEffect(() => {
        const siteName = process.env.REACT_APP_SITE_NAME ?? "Casino"
        document.title = pageName ? `${siteName} | ${pageName}` : siteName;

        return () => {
            document.title = siteName;
        };
    }, [pageName]);

    useEffect(() => {
        if (scrollContainerRef.current) {
            const height = scrollContainerRef.current.offsetHeight;
            setScrollContainerHeight(height);
        }
        if (scrollContainerDesktopRef.current) {
            const height = scrollContainerDesktopRef.current.offsetHeight;
            setScrollContainerDesktopHeight(height);
        }
    }, []);

    return <Stack height={'100dvh'} id={'layout'}>
        <Header/>
        {mediumScreenMin ? <SectionDivider mt={'20px'}>{pageName}</SectionDivider> : <DashboardMobileTitle title={pageName ?? 'Personal area'} backLink={'/dashboard'}/>}
        {mediumScreenMin ?
            <Stack flexGrow={1} sx={{ overflow: 'hidden' }} direction={'row'}>
                <Box width={'280px'}>
                    <DashboardMenu/>
                </Box>
                <Box
                    ref={scrollContainerDesktopRef}
                    flexGrow={1}
                    sx={{ overflowY: 'auto', scrollbarWidth: 'thin', scrollbarColor: '#15181D #15181D60' }}>
                    <Stack minHeight={scrollContainerDesktopHeight}>
                        {children}
                        <Box flexGrow={1}/>
                        {largeScreen && <Footer scrollContainerRef={scrollContainerDesktopRef}/>}
                    </Stack>
                </Box>
            </Stack>


            :


            <Stack
                ref={scrollContainerRef}
                flexGrow={1}
                sx={{ overflowY: 'auto', scrollbarWidth: 'thin', scrollbarColor: '#15181D #15181D60' }}
            >
                <Stack position={'relative'} minHeight={scrollContainerHeight}>
                    {children}
                    <Box flexGrow={1}/>
                    <Footer scrollContainerRef={scrollContainerRef}/>
                </Stack>
            </Stack>}
        {largeScreen ? <BottomMobileMenu/> : <Footer scrollContainerRef={scrollContainerDesktopRef}/>}

        { smallScreen && !disableForcedMobileLogin && (!Boolean(user) || !Boolean(token)) && <LoginWindow/> }
    </Stack>
}