import { FC } from "react";
import { Box, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from "@mui/material/IconButton";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { Link } from "react-router-dom";
import {theme} from "../../../themes/salta_la_banca/salta_la_banca_theme";

interface ImageSet {
    small: string
    medium: string
    large: string
}

interface SectionCardProps {
    imageUrl: ImageSet
    backgroundImgUrl: string
    title: string
    description: string
    url: string
}

export const SectionCard: FC<SectionCardProps> = ({imageUrl, title, description, url, backgroundImgUrl}) => {
    const { xxSmallScreen, smallScreen, largeScreen } = useCommonMediaQueries()
    const image = xxSmallScreen ? imageUrl.small : smallScreen ? imageUrl.medium : largeScreen ? imageUrl.large : imageUrl.large
    const cardWidth = xxSmallScreen ? '200px' : smallScreen ? '300px' : largeScreen ? '400px' : '19.2vw'
    const imageHeight = xxSmallScreen ? '185px' : smallScreen ? '278px' : largeScreen ? '370px' : '17.76vw'
    const boxHeight = xxSmallScreen ? '145px' : smallScreen ? '217px' : largeScreen ? '290px' : '13.92vw'
    const padding = xxSmallScreen ? '8px' : smallScreen ? '8px' : largeScreen ? '12px' : '1vw'
    const imageOffset = xxSmallScreen ? '32px' : smallScreen ? '53px' : largeScreen ? '68px' : '2.84vw'
    const titleSize = xxSmallScreen ? '24px' : smallScreen ? '36px' : largeScreen ? '48px' : '2.3vw'
    const descriptionSize = xxSmallScreen ? '18px' : smallScreen ? '27px' : largeScreen ? '36px' : '1.73vw'

    return <Box width={cardWidth} minWidth={cardWidth} maxWidth={cardWidth} pt={imageOffset}>
        <Stack  sx={{ backgroundColor: '#15181D' }} borderRadius={'12px'} padding={padding} height={'100%'}>
            <Box
                position={'relative'}
                sx={{backgroundImage: `url(${backgroundImgUrl})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}
                height={boxHeight}
                width={'100%'}
                border={`1px solid ${theme.palette.primary.main}`}
                borderRadius={'6px'}
            >
                <Box
                    sx={{ backgroundImage: `url(${image})`, backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} position={'absolute'} bottom={0} left={`-${padding}`} right={`-${padding}`} height={imageHeight}
                />
            </Box>
            <Stack padding={padding} flexGrow={1}>
                <Typography color={'primary'} fontSize={titleSize} sx={{userSelect: 'none'}} lineHeight={0.8} py={padding}>
                    {title}
                </Typography>
                <Stack direction={'row'} alignItems={'start'} justifyContent={'space-between'} flexGrow={1} gap={'8px'}>
                    <Typography fontSize={descriptionSize} fontWeight={500} lineHeight={1} sx={{userSelect: 'none'}}>
                        {description}
                    </Typography>
                    <Box alignSelf={'end'}>
                        <Link to={url}>
                            <IconButton color={'primary'} sx={{borderColor: 'inherit', borderWidth: '1px', borderStyle: 'solid'}}>
                                <ArrowForwardIcon/>
                            </IconButton>
                        </Link>
                    </Box>
                </Stack>
            </Stack>
        </Stack>
    </Box>
}