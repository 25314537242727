import React from 'react';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Lang } from "../../../features/localisation/useLanguageInterface";
import { useLanguage } from "../../../features/localisation/useLanguage";

import EnIcon from '../../../../assets/oropuroV2/langs/united-kingdom.png'
import EsIcon from '../../../../assets/oropuroV2/langs/spain.png'
import PtIcon from '../../../../assets/oropuroV2/langs/portugal.png'
import RuIcon from '../../../../assets/oropuroV2/langs/russia.png'
import DeIcon from '../../../../assets/oropuroV2/langs/germany.png'
import FrIcon from '../../../../assets/oropuroV2/langs/france.png'
import { Flag } from "./Flag";

const LanguageSelector: React.FC = () => {
    const isDevMode = process.env.REACT_APP_ENVIRONMENT === "live"
    const { switchLanguage, languagePack: { lang } } = useLanguage();

    const handleLanguageChange = (event: SelectChangeEvent<unknown>) => {
        switchLanguage(event.target.value as Lang);
    };

    return (
        <FormControl
            size={'small'}
            variant={'outlined'}
        >
            <Select
                labelId="language-selector-label"
                id="language-selector"
                value={lang}
                onChange={handleLanguageChange}
                sx={{
                    borderRadius: '12px',
                    color: 'grey.400',
                    borderColor: 'grey.800',
                    borderWidth: '2px',
                    '&:hover': {
                        borderColor: 'grey.700',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'grey.800',
                        borderWidth: '2px',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'grey.700',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'grey.700',
                    },
                }}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            borderRadius: '12px',
                            // marginTop: '8px',
                            '& .MuiMenuItem-root': {
                                color: 'grey.400',
                            },
                        },
                    },
                }}
            >
                <MenuItem value={Lang.EN}><Flag imageUrl={EnIcon}/></MenuItem>
                <MenuItem value={Lang.ES}><Flag imageUrl={EsIcon}/></MenuItem>
                <MenuItem value={Lang.PT}><Flag imageUrl={PtIcon}/></MenuItem>
                {isDevMode && <MenuItem value={Lang.RU}><Flag imageUrl={RuIcon}/></MenuItem>}
                {isDevMode && <MenuItem value={Lang.DE}><Flag imageUrl={DeIcon}/></MenuItem>}
                {isDevMode && <MenuItem value={Lang.FR}><Flag imageUrl={FrIcon}/></MenuItem>}
            </Select>
        </FormControl>
    );
};

export default LanguageSelector;
