import { FC } from "react";
import { Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

interface DashboardMobileTitleProps {
    title: string
    backLink?: string
}

export const DashboardMobileTitle: FC<DashboardMobileTitleProps> = ({ title, backLink }) => {
    return <Stack direction={'row'} alignItems={'center'} px={'10px'} textTransform={'uppercase'}>
        {backLink ? <Link to={backLink}>
                <IconButton>
                    <ArrowBackIosIcon/>
                </IconButton>
            </Link> :
            <IconButton>
                <ArrowBackIosIcon/>
            </IconButton>}
        <Typography>
            {title}
        </Typography>
    </Stack>
}