import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from "react";

const ARSIcon: FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M431.2,168.4c14.6,0,29.2-11.7,29.2-29.2S445.8,110,431.2,110h-40.9C366.9,57.5,314.4,22.4,256,22.4H139.2
		c-17.5,0-29.2,11.7-29.2,29.2V110H80.8c-17.5,0-29.2,11.7-29.2,29.2s11.7,29.2,29.2,29.2H110v29.2H80.8
		c-17.5,0-29.2,11.7-29.2,29.2S63.3,256,80.8,256H110v204.4c0,14.6,11.7,29.2,29.2,29.2s29.2-14.6,29.2-29.2v-146H256
		c46.7,0,90.5-23.4,116.8-58.4h58.4c14.6,0,29.2-11.7,29.2-29.2s-14.6-29.2-29.2-29.2h-32.1c2.9-8.8,2.9-20.4,2.9-29.2H431.2z
		 M168.4,80.8H256c26.3,0,49.6,11.7,64.2,29.2H168.4V80.8z M331.9,212.2C317.3,238.5,288.1,256,256,256h-87.6v-87.6h175.2
		C343.6,183,340.7,197.6,331.9,212.2z" fill="currentColor"/>
            </svg>
        </SvgIcon>
    );
}

export default ARSIcon;