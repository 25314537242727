import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from "react";

const PromotionsIcon: FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.7508 2.83301C15.3946 2.83294 16.027 3.00235 16.5845 3.32422C17.1421 3.64609 17.605 4.10908 17.9268 4.66662C18.2487 5.22416 18.418 5.8566 18.4179 6.50037C18.4178 7.14413 18.2482 7.77651 17.9261 8.33393L22.0841 8.33301V10.1663H20.2508V19.333C20.2508 19.5761 20.1542 19.8093 19.9823 19.9812C19.8104 20.1531 19.5773 20.2497 19.3341 20.2497H4.66748C4.42437 20.2497 4.19121 20.1531 4.0193 19.9812C3.84739 19.8093 3.75081 19.5761 3.75081 19.333V10.1663H1.91748V8.33301L6.07548 8.33393C5.62069 7.54641 5.47478 6.61798 5.66608 5.72892C5.85738 4.83986 6.37225 4.05362 7.1107 3.52285C7.84916 2.99209 8.7585 2.75468 9.66217 2.85674C10.5658 2.95879 11.3993 3.39301 12.0008 4.0751C12.3442 3.68403 12.7672 3.37089 13.2415 3.15667C13.7158 2.94245 14.2304 2.8321 14.7508 2.83301V2.83301ZM12.9175 10.1663H11.0841V19.333H12.9175V10.1663ZM9.25081 4.66635C8.77508 4.66422 8.31715 4.8471 7.97376 5.17636C7.63037 5.50562 7.42842 5.95547 7.41057 6.43087C7.39272 6.90628 7.56037 7.37001 7.8781 7.72409C8.19583 8.07817 8.63876 8.29488 9.11331 8.32843L9.25081 8.33301H11.0841V6.49968C11.0842 6.06157 10.9273 5.63794 10.6419 5.3055C10.3566 4.97306 9.96162 4.7538 9.52856 4.68743L9.3874 4.67093L9.25081 4.66635ZM14.7508 4.66635C14.2883 4.6662 13.8428 4.84089 13.5036 5.15539C13.1645 5.46989 12.9568 5.90095 12.9221 6.36218L12.9175 6.49968V8.33301H14.7508C15.2133 8.33316 15.6588 8.15847 15.998 7.84397C16.3371 7.52948 16.5449 7.09841 16.5796 6.63718L16.5841 6.49968C16.5841 6.01345 16.391 5.54713 16.0472 5.20332C15.7034 4.8595 15.237 4.66635 14.7508 4.66635V4.66635Z" fill="currentColor"/>
            </svg>
        </SvgIcon>
    );
}

export default PromotionsIcon;