import { FC } from "react";
import { Box, Stack } from "@mui/material";
import { GameObj } from "../../../features/play_games/playGamesModels";
import Typography from "@mui/material/Typography";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {theme} from "../../../themes/salta_la_banca/salta_la_banca_theme";

interface GameCardProps {
    game: GameObj
    currentDomain: string
    onGameClick?: (game: GameObj) => void
    height?: string
}

export const GameCard: FC<GameCardProps> = ({game, currentDomain,  onGameClick, height: customHeight}) => {
    const { smallScreen } = useCommonMediaQueries()
    const height = smallScreen ? '100px' : '9.8vw'
    const imageURL = `${currentDomain}/cdn/game_img/sqr_mid/${game.Pic}`

    return <Box
        id={`${game.Name}-${game.internal_id}`}
        onClick={onGameClick ? () => onGameClick(game) : undefined}
        width={customHeight ?? height}
        minWidth={customHeight ?? height}
        height={customHeight ?? height}
        borderRadius={'8px'}
        sx={{
            backgroundColor: '#15181D',
            position: 'relative',
            overflow: 'hidden',
            cursor: onGameClick ? 'pointer' : 'default',
        }}
    >
        <Stack
            width={'100%'}
            height={'100%'}
            alignItems={'center'}
            justifyContent={'center'}
            padding={'5px'}
            position={'absolute'}
            top={0}
            left={0}
            zIndex={1}
        >
            <Typography fontWeight={200} fontSize={'10px'} textAlign={'center'} color={'primary'}>
                {game.Name}
            </Typography>
        </Stack>

        <Box
            borderRadius={'8px'}
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundImage: `url(${imageURL})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: theme.palette.primary.main,
                transition: 'opacity 0.3s ease',
                zIndex: 2,
            }}
        />
    </Box>
}