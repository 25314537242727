import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from "react";

const UserIcon: FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_10005_1944)">
                    <path d="M6 7C6 5.67392 6.52678 4.40215 7.46447 3.46447C8.40215 2.52678 9.67392 2 11 2C12.3261 2 13.5979 2.52678 14.5355 3.46447C15.4732 4.40215 16 5.67392 16 7C16 8.32608 15.4732 9.59785 14.5355 10.5355C13.5979 11.4732 12.3261 12 11 12C9.67392 12 8.40215 11.4732 7.46447 10.5355C6.52678 9.59785 6 8.32608 6 7ZM4.822 14.672C6.425 13.694 8.605 13 11 13C11.447 13 11.887 13.024 12.316 13.07C12.4878 13.0884 12.6518 13.151 12.7922 13.2517C12.9326 13.3524 13.0445 13.4878 13.117 13.6446C13.1895 13.8014 13.2202 13.9743 13.206 14.1465C13.1918 14.3186 13.1332 14.4842 13.036 14.627C12.3586 15.6212 11.9975 16.7969 12 18C12 18.92 12.207 19.79 12.575 20.567C12.6467 20.7184 12.6792 20.8853 12.6696 21.0525C12.66 21.2198 12.6085 21.3819 12.5199 21.524C12.4313 21.6662 12.3085 21.7838 12.1626 21.8661C12.0167 21.9484 11.8525 21.9927 11.685 21.995L11 22C8.771 22 6.665 21.86 5.087 21.442C4.302 21.234 3.563 20.936 3.003 20.486C2.41 20.01 2 19.345 2 18.5C2 17.713 2.358 16.977 2.844 16.361C3.338 15.736 4.021 15.161 4.822 14.671V14.672ZM16 18C16 17.7348 16.1054 17.4804 16.2929 17.2929C16.4804 17.1054 16.7348 17 17 17H17.99C18.548 17 19 17.452 19 18.01V20.134C19.1906 20.2441 19.3396 20.414 19.4238 20.6173C19.5081 20.8207 19.5229 21.0462 19.4659 21.2588C19.4089 21.4714 19.2834 21.6593 19.1087 21.7933C18.9341 21.9273 18.7201 22 18.5 22H18.01C17.7421 22 17.4852 21.8936 17.2958 21.7042C17.1064 21.5148 17 21.2579 17 20.99V19C16.7348 19 16.4804 18.8946 16.2929 18.7071C16.1054 18.5196 16 18.2652 16 18ZM18 14C17.7451 14.0003 17.5 14.0979 17.3146 14.2728C17.1293 14.4478 17.0178 14.687 17.0028 14.9414C16.9879 15.1958 17.0707 15.4464 17.2343 15.6418C17.3979 15.8373 17.6299 15.9629 17.883 15.993L18.002 16C18.2569 15.9997 18.502 15.9021 18.6874 15.7272C18.8727 15.5522 18.9842 15.313 18.9992 15.0586C19.0141 14.8042 18.9313 14.5536 18.7677 14.3582C18.6041 14.1627 18.3721 14.0371 18.119 14.007L18 14Z" fill="currentColor"/>
                </g>
                <defs>
                    <clipPath id="clip0_10005_1944">
                        <rect width="24" height="24" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
}

export default UserIcon;