import { FC } from "react";
import { Box, Button, Stack } from "@mui/material";
import { Logo } from "../logo/Logo";
import Typography from "@mui/material/Typography";
import { FooterProps } from "./Footer";
import { Link } from "react-router-dom";
import LanguageSelector from "../language_selector/LanguageSelector";
import { useLanguage } from "../../../features/localisation/useLanguage";
import BreakedLine from "../../common/BreakedLine";
import {theme} from "../../../themes/salta_la_banca/salta_la_banca_theme";

export const MobileFooter: FC<FooterProps> = ({scrollContainerRef}) => {
    const { languagePack: {pack: {argentina: {footer: footerLang}}}} = useLanguage()

    const scrollToTop = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    return <Stack sx={{ backgroundColor: theme.palette.surface.main }}>
        <Box display={'flex'} justifyContent={'center'} padding={'15px'}>
            <Link to={'/'}><Logo/></Link>
        </Box>
        <Box px={'20px'} py={'6px'}>
            <Typography lineHeight={1.2} fontWeight={600} textTransform={'uppercase'} textAlign={'center'}
                        sx={{ opacity: 0.6 }}>
                <BreakedLine text={footerLang.copyright}/>
            </Typography>
        </Box>
        <Stack direction={'row'} justifyContent={'space-evenly'} padding={'15px'}>
            <LanguageSelector/>
            <Button
                variant={'outlined'}
                sx={{
                    color: 'grey.400',
                    borderColor: 'grey.800',
                    '&:hover': {
                        borderColor: 'grey.700',
                    }
                }}
                onClick={scrollToTop}
            >
                {footerLang.backToTopBTN}
            </Button>
        </Stack>
    </Stack>
}