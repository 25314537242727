import { FC } from "react";
import { Stack } from "@mui/material";
import { convertCentsToCurrencyString } from "../../../features/common_funcs";
import Typography from "@mui/material/Typography";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";

interface ShowBalanceProps {
    balance: number
    currency: string
    minWidth?: string
    justify?: string
    dark?: boolean
}

export const ShowBalance: FC<ShowBalanceProps> = ({ balance, currency, minWidth, justify, dark = true}) => {
    const { smallScreen } = useCommonMediaQueries()

    return <Stack minWidth={minWidth ?? undefined} width={'100%'} direction={'row'} justifyContent={justify ?? smallScreen ? 'start' : 'end'} borderRadius={'12px'} sx={{backgroundColor: dark ? '#15181D' : '#22242A'}} py={'4px'} px={'10px'} gap={'5px'}>
        <Typography color={'primary'} sx={{userSelect: 'none'}}>
            {convertCentsToCurrencyString(balance)}
        </Typography>
        <Typography color={'white'} sx={{userSelect: 'none'}}>
            {currency}
        </Typography>
    </Stack>
}