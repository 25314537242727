import Stack from "@mui/material/Stack";
import { FC } from "react";
import { Box } from "@mui/material";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";

interface ProviderCardProps {
    providerName: string
    providerLogo: string
    gamesCount: number
    onClick?: () => void
}

export const ProviderCard: FC<ProviderCardProps> = ({ providerName, providerLogo, gamesCount, onClick }) => {
    const { xxSmallScreen } = useCommonMediaQueries()
    const height = xxSmallScreen ? '69px' : '100px';
    const width = xxSmallScreen ? '110px' : '160px';
    const picHeight = xxSmallScreen ? '33px' : '50px';
    const picWidth = xxSmallScreen ? '95px' : '143px';

    return <Stack
        onClick={onClick}
        sx={{backgroundColor: '#15181D', userSelect: 'none', cursor: onClick ? 'pointer' : 'default'}}
        minWidth={width} width={width} height={height}
        borderRadius={'16px'}
        alignItems={'center'} justifyContent={'center'}
        gap={'6px'}
    >
        <Box
            height={picHeight} width={picWidth}
            sx={{
                backgroundImage: `url(${providerLogo})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                // filter: 'contrast(50%)  saturate(0%)',
                filter: 'contrast(50%)',
                userSelect: 'none'}}
        />
        {/*<Typography fontSize={'12px'} sx={{opacity: 0.6, userSelect: 'none'}} lineHeight={0.8}>*/}
        {/*    {`${gamesCount} games`}*/}
        {/*</Typography>*/}
    </Stack>
}