import React, { FC } from 'react';

interface BreakedLineProps {
    text: string;
}

const BreakedLine: FC<BreakedLineProps> = ({ text }) => {
    return (
        <>
            {text.split('<br/>').map((line, index, array) => (
                <React.Fragment key={index}>
                    {line}
                    {index < array.length - 1 && <br />}
                </React.Fragment>
            ))}
        </>
    );
};

export default BreakedLine