import { Box, Stack } from "@mui/material";
import { GameObj } from "../../features/play_games/playGamesModels";
import { FC } from "react";
import { getCurrentDomain } from "../../config";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { GameCard } from "../oropuro_layout/games/GameCard";

interface GamesListProps {
    games: GameObj[]
    onGameClick?: (game: GameObj) => void
}

export const FullGamesList: FC<GamesListProps> = ({games, onGameClick}) => {
    const currentDomain = getCurrentDomain();
    const { xxSmallScreen, smallScreen } = useCommonMediaQueries()
    const height = xxSmallScreen ? '30vw' : smallScreen ? '23vw' : '10vw'
    const gap = smallScreen ? '2vw' : '1vw'

    const itemsPerRow = xxSmallScreen ? 3 : smallScreen ? 4 : 9; // Количество элементов в ряду
    const remainingItems = itemsPerRow - (games.length % itemsPerRow);

    // Создаем массив пустых блоков
    const emptyBlocks = Array.from({ length: remainingItems }, (_, index) => (
        <Box key={`empty-${index}`} minWidth={height} />
    ));

    return <Stack direction={'row'} flexWrap={'wrap'} justifyContent={'space-between'} rowGap={gap}>
        {games.map(game => <GameCard height={height} key={game.internal_id} currentDomain={currentDomain ?? ""} game={game} onGameClick={onGameClick}/>)}
        {emptyBlocks}
    </Stack>
}