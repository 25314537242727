import React, { FC, useEffect } from "react";
import { PageLayout } from "../../components/oropuro_layout/PageLayout";
import { DashboardMenu } from "../../components/oropuro_layout/dashboard_menu/DashboardMenu";
import { useCommonMediaQueries } from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { useNavigate } from "react-router-dom";
import { LoginWindow } from "../../components/oropuro_layout/login/LoginWindow";
import { useUser } from "../../features/user/useUser";

export const PersonalAreaPage: FC = () => {
    const { xxSmallScreen, smallScreen, mediumScreenMin } = useCommonMediaQueries()
    const navigate = useNavigate();
    const { user, token } = useUser()

    useEffect(() => {
        if (mediumScreenMin) {
            navigate('/dashboard/user_info', { replace: true });
        }
    }, [mediumScreenMin, navigate]);


    return <PageLayout pageName={'Personal Area'} carouselDisabled>
        <DashboardMenu compact={xxSmallScreen}/>
        { smallScreen && (!Boolean(user) || !Boolean(token)) && <LoginWindow/> }
    </PageLayout>
}