import { FC, ReactNode } from "react";
import { Box, Stack } from "@mui/material";
import LeftPart from "../../../../assets/oropuroV2/decorated_title/left_part.svg";
import MiddlePart from "../../../../assets/oropuroV2/decorated_title/middle_part.svg";
import RightPart from "../../../../assets/oropuroV2/decorated_title/right_part.svg";
import Hexagone from "../../../../assets/oropuroV2/decorated_title/hexagone.svg"
import Typography from "@mui/material/Typography";

interface DecoratedTitleProps {
    children?: ReactNode
}

export const DecoratedTitle: FC<DecoratedTitleProps> = ({children}) => {
    return <Stack direction={'row'} px={'25px'} position={'relative'} minWidth={'100px'}>
        <Box
            left={0}
            position={'absolute'}
            height={'53px'} width={'28px'}
            sx={{backgroundImage: `url(${LeftPart})`, backgroundSize: 'auto 100%', backgroundRepeat: 'no-repeat'}}
        />
        <Box
            right={0}
            position={'absolute'}
            height={'53px'} width={'28px'}
            sx={{backgroundImage: `url(${RightPart})`, backgroundSize: 'auto 100%', backgroundRepeat: 'no-repeat'}}
        />

        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            position={'relative'}
            height={'53px'} width={'100%'}
            gap={'16px'}
            sx={{backgroundImage: `url(${MiddlePart})`, backgroundSize: 'auto 100%', backgroundRepeat: 'repeat-x'}}
        >
<Hexa/>
            <Typography noWrap={true} fontSize={'20px'} textTransform={'uppercase'} fontWeight={500} sx={{userSelect: 'none'}}>
                {children}
            </Typography>
            <Hexa/>
        </Box>
    </Stack>
}

const Hexa = () => <Box width={'14px'} height={'17px'} sx={{backgroundImage: `url(${Hexagone})`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat'}}/>