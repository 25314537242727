import { FC } from "react";
import { Stack } from "@mui/material";
import { GameObj } from "../../../features/play_games/playGamesModels";
import { GameCard } from "./GameCard";
import { getCurrentDomain } from "../../../config";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";

interface ShortGamesListProps {
    games: GameObj[]
    onGameClick?: (game: GameObj) => void
}

export const ShortGamesList: FC<ShortGamesListProps> = ({games, onGameClick}) => {
    const currentDomain = getCurrentDomain();
    const { smallScreen } = useCommonMediaQueries()

    return <Stack
        py={'5px'}
        direction={'row'}
        sx={{overflowX: 'auto', scrollbarWidth: 'thin', scrollbarColor: '#15181D #15181D60', userSelect: 'none'}}
        gap={smallScreen ? '10px' : '1vw'}
        justifyContent={smallScreen || games.length < 9 ? 'start' : 'space-between'}
    >
        {games.map(game => <GameCard key={game.internal_id} currentDomain={currentDomain ?? ""} game={game} onGameClick={onGameClick}/>)}
    </Stack>
}