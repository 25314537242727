import { LangPack } from "../langModel";
import { Lang } from "../useLanguageInterface";

export const ptLang: LangPack = {
    lang: Lang.PT,
    langName: "Portuguese",
    langShort: "pt",
    pack: {
        common: {
            noBTN: "Não",
            yesBTN: "Sim",
            errorCodeTitle: "Código de erro",
            pageSizeSwitcherLabel: "Linhas por página",
            ageConfirmationMessage: "Confirma que tem 18 anos?",
            iConfirmBTN: "Confirmo",
        },
        dashboardMenu: {
            logOutConfirmationMessage: "Tem certeza de que deseja sair?",
            logOut: "Sair",
            depositMoney: "Depositar dinheiro",
            gamesHistory: "Histórico de jogos",
            transactions: "Transações",
            paymentAccounts: "Contas de pagamento",
            userInfo: "Informações do usuário",
            withDrawMoney: "Retirar dinheiro"
        },
        mobileMenu: {
            profile: 'Perfil',
            topUpBalance: 'Saldo de recarga',
            logout: 'Sair'
        },
        dashboard: {
            title: 'Painel de Controle',
            youMustBeLoggedMessage: 'Você precisa estar logado para ver esta seção!'
        },
        internalErrorMessages: {
            networkError: "Ocorreu um erro de rede ao executar a solicitação.",
            error502: "Ocorreu um erro de servidor 502 ao executar a solicitação."
        },
        errorMessages: {
            2: "Requisição vazia",
            3: "Endereço de e-mail já existente",
            4: "Número de telefone já existente",
            5: "Usuário já está logado",
            6: "Senha incorreta",
            7: "Resultado de jogos vazio",
            8: "ID do resultado não corresponde à ID da solicitação",
            9: "Erro na solicitação de resultado",
            10: "ID de sessão ausente na resposta",
            11: "Usuário já existente",
            12: "Seu token não é válido. Faça login novamente.",
            13: "Você não tem direitos suficientes para realizar esta ação",
            14: "Falta a ID da criança para esta ação",
            15: "Falta a quantia de dinheiro",
            16: "Ação não existe",
            17: "Falta o token",
            18: "Dinheiro insuficiente",
            19: "Você deve ser o pai deste usuário para realizar esta ação",
            20: "Usuário não existe",
            21: "Tipo de valor errado para este campo",
            22: "Falta a senha",
            23: "Falta o nome de usuário",
            24: "Falta a ID do jogo",
            25: "Falta a nova senha",
            26: "Não foi possível configurar o token",
            27: "Não foi possível excluir o token",
            28: "Falta a ID do usuário",
            29: "Formato de data e hora errado",
            30: "Não foi possível atualizar a proibição do usuário",
            31: "Este usuário deve estar na sua árvore para realizar esta ação",
            32: "Este usuário está proibido e não pode realizar nenhuma ação",
            33: "Moeda ausente",
            34: "Valor máximo ausente",
            35: "O tipo de limite deve ser único, diário, semanal ou mensal",
            36: "Tipo de limite ausente",
            37: "O provedor não existe",
            38: "Quantidade de condição de nível ausente",
            39: "Tipo de condição de nível ausente",
            40: "Título de nível ausente",
            41: "Cashback para este nível já existe",
            42: "Não existe cashback para este nível",
            43: "O bônus para este código promocional já existe",
            44: "O bônus não existe",
            45: "Código promocional ausente",
            46: "Hora de início ausente",
            47: "Quantidade de condição de depósito ausente",
            48: "Aposta ausente",
            49: "Dias de validade ausentes",
            50: "ID ausente",
            51: "Tipo de cashback ausente, deve ser 'aposta' ou 'depósito'",
            52: "Taxa de cashback ausente",
            53: "Esta moeda não está disponível",
            54: "Parâmetro de proibição ausente - verdadeiro ou falso",
            55: "O grupo bancário para esta moeda não é válido",
            56: "Nome do método de pagamento ausente",
            57: "Parâmetros obrigatórios para este método de pagamento ausentes",
            58: "Este método de pagamento não existe",
            59: "Este método de pagamento não suporta a moeda deste usuário",
            60: "Este usuário não possui uma conta existente para este sistema de pagamento",
            61: "O pai deste usuário não possui uma conta existente para este sistema de pagamento",
            62: "ID de transferência ausente",
            63: "Nenhuma transferência com esta ID",
            64: "Esta transferência é solicitada por um pai diferente",
            65: "Você ainda tem um bônus que não expirou"
        },
        loginForm: {
            usernameFieldLabel: "Nome de usuário",
            passwordFieldLabel: "Senha",
            loginBTN: "Entrar"
        },
        userInfoForm: {
            emailIsRequiredMess: 'E-mail obrigatório',
            notValidEmail: 'Digite um endereço de e-mail válido',
            emailFieldLabel: "E-mail",
            phoneFieldLabel: "Telefone",
            firstnameFieldLabel: "Nome",
            lastnameFieldLabel: "Sobrenome",
            saveInfoBTN: "Salvar informações",
            currentPassFieldLabel: "Senha atual",
            newPassFieldLabel: "Nova senha",
            retypePassFieldLabel: "Digite a nova senha novamente",
            updatePassBTN: "Atualizar senha",
            emptyFieldWarningMessage: "Este campo não deve estar vazio",
            passNotMatchWarningMessage: "Nova senha e senha de confirmação não correspondem",
            usernameLabel: "Usuario"
        },
        transactionsTable: {
            amountTableHeader: "Quantidade",
            methodTableHeader: "Método",
            statusTableHeader: "Status",
            depositWithdrawTableHeader: "Depósito/Retirada",
            dateTimeTableHeader: "Data/Hora"
        },
        gamesHistory: {
            providerTableHeader: 'Fornecedor',
            gameTableHeader: 'Jogo',
            betAmountTableHeader: 'Valor da aposta',
            winAmountTableHeader: 'Valor ganho',
            dateTimeTableHeader: 'Data/Hora'
        },
        paymentSystems: {
            noAvailablePaymentMethodsMessage: "Não há mais métodos de pagamento disponíveis",
            paymentMethodsDropdownLabel: "Métodos de pagamento",
            addBTN: "Adicionar",
            yourPaymentAccountsTitle: "Suas contas de pagamento",
            addPaymentMethodBTN: "Adicionar método de pagamento"
        },
        moneyInput: {
            maxAmountMessage: "Valor máximo",
            exceedAmountError: "Valor máximo excedido.",
            moreThanOneDotError: "Você inseriu um ponto a mais.",
            moreThanTwoDecimals: "Você inseriu mais de dois decimais."
        },
        withdrawMoney: {
            requestPayoutLabel: "Retirar do usuário",
            moneySuccessfulRequestedMessage: "Você solicitou %money% para pagamento.",
            requestBtn: "Solicitar dinheiro"
        },
        argentina: {
            common: {
                linkToAllTitle: "Todos",
                topGames: 'Jogos principais',
                providers: 'Provedores',
                seeAllBTN: 'Ver todos',
                returnBackBTN: 'Voltar'
            },
            dashboard: {
                sideMenu: {
                    userInfo: 'Informações do usuário',
                    transactions: 'Transações',
                    gamesHistory: 'Histórico de jogos',
                    logoutBTN: 'Sair'
                },
                userInfo: {
                    userInfoSection: 'Informações do usuário',
                    changePasswordSection: 'Alterar senha'
                }
            },
            carousel: {
                slide1: {
                    header: 'Domine o Totem<br/> e Conquiste a Vitória',
                    tagline: 'Role e Vença com Galaxsys!'
                },
                slide2: {
                    header: 'Descubra a Emoção<br/>apenas em Salta la Banca',
                    tagline: 'Junte-se ao jogo hoje!'
                },
                slide3: {
                    header: 'Imagine Mais, Ganhe Mais<br/>no Imagine Live',
                    tagline: 'As apostas são reais'
                },
                slide4: {
                    header: "Sinta a emoção do jogo!<br/>Viva o momento da vitória!",
                    tagline: "Junte-se agora!"
                },
                slide5: {
                    header: "Ezugi está chegando",
                    tagline: "No final desta semana!"
                }
            },
            menuLinkTitles: {
                home: "Início",
                casino: "Cassino",
                liveCasino: "Cassino ao vivo",
                virtualGames: "Jogos virtuais",
            },
            sideBarSections: {
                games: "Jogos",
                topCrashGames: "Jogos Top Crash",
                other: "Outro",
                sportsRules: "Regras de esportes"
            },
            headerMenu: {
                home: 'Início',
                casino: 'Cassino',
                slots: 'Caça-níqueis',
                shooters: 'Tiro',
                tableGames: 'Jogos de mesa',
                liveCasino: 'Cassino ao vivo',
                crash: 'Crash',
                sport: 'Esportes',
                menu: 'Menu'
            },
            categoriesDescription: {
                casino: 'Faça apostas esportivas conosco!',
                slots: 'Gire e ganhe grandes jackpots!',
                shooters: 'Mire, atire e marque pontos!',
                tableGames: 'Jogos clássicos, diversão infinita!',
                liveCasino: 'Jogos com crupieres ao vivo!',
                crash: 'Aposte, observe e saque rápido!',
                sport: 'Jogue centenas de jogos!'
            },
            footer: {
                backToTopBTN: 'Voltar ao topo',
                copyright: 'Todos os direitos reservados © Salta la Banca 2024<br/>18+ aposte com responsabilidade'
            }
        }
    }
}
