import { FC } from "react";
import { useCommonMediaQueries } from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { TopGameHolder } from "./models/models";
import { useSnackbar, VariantType } from "notistack";
import { useUser } from "../../../features/user/useUser";
import { usePlayGames } from "../../../features/play_games/usePlayGames";
import { TopGamesListMobile } from "./TopGamesListMobile";
import { TopGamesListDesktop } from "./TopGamesListDesktop";

interface TopGamesListProps {
    gamesList: TopGameHolder[]
}

export const TopGamesList: FC<TopGamesListProps> = ({gamesList}) => {
    const { largeScreen } = useCommonMediaQueries()
    const { token } = useUser();
    const { isGameSessionLoading, startGameSession } = usePlayGames();
    const { enqueueSnackbar } = useSnackbar();

    const sendSnackBar = (variant: VariantType, message: string) => {
        enqueueSnackbar(message, { variant });
    };

    const clickHandler = (game: TopGameHolder) => {
        return token ? () => {
            if (!isGameSessionLoading) {
                startGameSession(game.gameID);
            }
        } : () => {
            sendSnackBar('warning', 'Login required');
        };
    };

    return largeScreen ? <TopGamesListMobile gamesList={gamesList} onGameClick={clickHandler}/> : <TopGamesListDesktop gamesList={gamesList} onGameClick={clickHandler}/>
}