import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from "react";

const HomeIcon: FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.7159 10.8291C20.7155 10.8287 20.715 10.8283 20.7146 10.8279L13.3721 3.4856C13.0591 3.17249 12.643 3 12.2004 3C11.7578 3 11.3416 3.17235 11.0285 3.48546L3.68981 10.8241C3.68734 10.8265 3.68486 10.8291 3.68239 10.8316C3.03969 11.478 3.04079 12.5268 3.68555 13.1716C3.98012 13.4663 4.36917 13.637 4.78514 13.6548C4.80204 13.6565 4.81906 13.6573 4.83623 13.6573H5.12888V19.0608C5.12888 20.13 5.99886 21 7.06838 21H9.94103C10.2322 21 10.4684 20.7639 10.4684 20.4727V16.2363C10.4684 15.7484 10.8653 15.3515 11.3532 15.3515H13.0475C13.5355 15.3515 13.9324 15.7484 13.9324 16.2363V20.4727C13.9324 20.7639 14.1684 21 14.4597 21H17.3324C18.4019 21 19.2719 20.13 19.2719 19.0608V13.6573H19.5432C19.9857 13.6573 20.4018 13.4849 20.715 13.1718C21.3605 12.526 21.3608 11.4754 20.7159 10.8291V10.8291Z" fill="currentColor"/>
            </svg>
        </SvgIcon>
    );
}

export default HomeIcon;