import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from "react";

const CasinoIcon: FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.5 10.868V2.50755C4.87681 3.48656 5.45699 3.23267 6.4313 2.80629C7.14086 2.49578 8.05947 2.09378 9.64706 2.0099C11.2241 1.92658 12.4364 2.38388 13.611 2.827C15.2426 3.44248 16.8017 4.0306 19.1651 3.10473C20.7514 2.50755 20.7514 3.90096 20.0574 4.99578C18.167 8.1679 17.0627 9.69231 15.5557 13.2821C14.0487 16.8718 14.5511 20.9744 14.5511 20.9744C14.5511 20.9744 14.5511 22 13.5464 22H8.18676C7.51857 22 7.51857 20.9744 7.51857 20.9744C7.39059 14.9362 9.47305 13.305 11.9556 11.4108C13.3196 10.37 14.8484 9.20354 16.2899 7.0859C12.0709 9.23943 9.60543 8.23477 8.18676 7.65667C7.63844 7.43323 7.2465 7.27351 6.97011 7.38449C5.97865 7.78261 5.18548 8.67837 4.49146 10.868H3.5Z" fill="currentColor"/>
            </svg>
        </SvgIcon>
    );
}

export default CasinoIcon;